
import { mapGetters, mapActions } from 'vuex';
import CookieBannerWrapper from '@/components/ui/banner/accept-cookie-banner-wrapper.vue';

export default {
  components: {
    CookieBannerWrapper,
  },

  data() {
    return {
      bannerTop: null,
      page: null,
      sidebarLoading: false,
      windowWidth: 0,
      showHubSideBar: false,
    };
  },

  async fetch() {
    try {
      const banners = {
        index: 'landing',
      };
      const param = banners[this.$route.name];
      const res = await this.$axios.get(`/api/caap/specials/${param || 'caap_client'}`);
      this.bannerTop = res?.data;
      this.calcOffsetTop();
    } catch (error) {
      this.calcOffsetTop();
    }
  },

  computed: {
    isOverlayEnabled() {
      return this.sidebar.show;
    },
    isSidebarFixed() {
      return this.sidebar.fixed;
    },
    isSidebarOpen() {
      return this.sidebar.show;
    },
    isSidebarOpenOrEnabled() {
      return this.isSidebarOpen || this.isSidebarFixed;
    },
    ...mapGetters('ui', ['sidebar', 'offsetTop']),
  },

  watch: {
    $route(to) {
      this.page = to.name;
    },
  },

  watchQuery: ['page'],

  beforeCreate() {
    this.$store.dispatch('ui/resetSidebar');
    this.$store.dispatch('ui/setOffsetTop', 0);
  },

  created() {
    this.sidebarLoading = true;
    this.$nuxt.$on(this.$config.event.toggle_cookie_banner, this.toggleCookieBanner);
  },

  destroyed() {
    window.removeEventListener('resize', this.calcOffsetTop);
    this.$nuxt.$off(this.$config.event.toggle_cookie_banner, this.toggleCookieBanner);
  },

  beforeUpdate() {
    this.calcOffsetTop();
  },

  mounted() {
    this.sidebarLoading = false;
    window.addEventListener('resize', this.calcOffsetTop);
  },

  methods: {
    calcOffsetTop() {
      this.$nextTick(() => {
        this.windowWidth = window.innerWidth;
        if (this.windowWidth >= 1024 && !this.isSidebarFixed) {
          this.setOffsetTop(0);
          return;
        }
        const offset = this.$refs?.navbar?.$el?.clientHeight ?? 0;
        this.setOffsetTop(offset);
      });
    },
    toggleCookieBanner(status) {
      this.$refs.cookieBanner?.changeBannerVisibility(status);
    },
    toggleHubSidebar(value) {
      this.showHubSideBar = value;
    },
    ...mapActions('ui', ['toggleSidebar', 'resetSidebar', 'setOffsetTop']),
  },
};
