import moment from 'moment';
import Vue from 'vue';
import humanizeDuration from 'humanize-duration';
import timeFormatUtil from 'hh-mm-ss';

const utcToLocal = (datetime) => {
  return moment.utc(datetime).local();
};

// Aug 21
Vue.filter('shortFormatDate', (value) => {
  if (!value) {
    return '';
  }
  return utcToLocal(value).format('MMM D');
});

// Friday, Aug 21
Vue.filter('shortFormatDateWithDayName', (value) => {
  if (!value) {
    return '';
  }
  return utcToLocal(value).format('dddd, MMM D');
});

// Fri, Aug 21
Vue.filter('shortFormatDateWithNumberDayIndex', (value) => {
  if (!value) {
    return '';
  }
  return utcToLocal(value).format('ddd, MMM Do');
});

// Aug 21, 2020
Vue.filter('mediumFormatDate', (value) => {
  if (!value) {
    return '';
  }
  return utcToLocal(value).format('MMM D, YYYY');
});

// Fri, Aug 21, 2020
Vue.filter('formatDate', (value) => {
  if (!value) {
    return '';
  }
  return utcToLocal(value).format('ddd, MMM D, YYYY');
});

// 12:29, AM EDT
Vue.filter('formatTime', (value) => {
  if (!value) {
    return '';
  }
  return `${utcToLocal(value).format('h:mm A')}`;
});

Vue.filter('formatZoneName', (value) => {
  if (!value) {
    return '';
  }
  return moment.utc(value).tz(moment.tz.guess(true)).zoneAbbr();
});

// Fri, Aug 21, 2020 12:29, AM EDT
Vue.filter('formatDateTime', (value) => {
  if (!value) {
    return '';
  }
  return `${utcToLocal(value).format('ddd, MMM D, YYYY hh:mm A')}`;
});

// 18 (days from now)
Vue.filter('remainingDays', (value) => {
  if (!value) {
    return '';
  }
  const now = utcToLocal(new Date());
  const then = utcToLocal(value);
  let differenceInDays = `${then.diff(now, 'days') + 1}`;
  differenceInDays = Math.max(differenceInDays, 0);
  return `${differenceInDays}`;
});

// 14 (days from the date)
Vue.filter('spentDays', (value) => {
  if (!value) {
    return '';
  }
  const now = utcToLocal(new Date());
  const then = utcToLocal(value);
  return `${now.diff(then, 'days') + 1}`;
});

// custom
const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'min',
      s: () => 's',
      ms: () => 'ms',
    },
  },
  units: ['h', 'm'],
  round: true,
  spacer: '',
  conjunction: ' ',
  serialComma: false,
});

// 1h 35min
Vue.filter('formatDuration', (milliseconds) => {
  if (!milliseconds) {
    return '';
  }
  return shortEnglishHumanizer(milliseconds);
});

// 05:18:00 => 5h 18min
Vue.filter('formatTimeToHRS', (duration) => {
  if (!duration) {
    return '';
  }
  const milliseconds = timeFormatUtil.toMs(duration, 'hh:mm:ss');
  return shortEnglishHumanizer(milliseconds);
});

// abc_def => abc def
Vue.filter('removeUnderline', (value) => {
  if (!value) {
    return '';
  }
  return value.replace(/[_-]/g, ' ');
});

Vue.filter('removeMiliSeconds', (value) => {
  if (!value) {
    return '';
  }
  const splittedTime = value.split(':');
  return splittedTime[0] + ':' + splittedTime[1];
});

Vue.filter('removeUnderline', (value) => {
  if (!value) {
    return '';
  }
  return value.replace(/[_-]/g, ' ');
});

Vue.filter('monthYear', (value) => {
  if (!value) {
    return '';
  }
  return moment.utc(value, 'mm/Y').format('mm / YY');
});

// 2021-08-18
Vue.filter('dashFormatDate', (value) => {
  if (!value) {
    return '';
  }
  return moment.utc(value).format('YYYY-mm-DD');
});

// 2022-02-20T06:03:55  => 3 Hours ago
Vue.filter('durationAgo', (value) => {
  if (!value) {
    return '';
  }
  const now = moment.utc(new Date());
  const start = moment.utc(value);
  return moment.duration(now.diff(start)).humanize({ d: 7, w: 4 }) + ' ago';
});
