export default {
  currentIndex: (state) => state.currentIndex,
  currentLesson: (state) => state.currentLesson,
  currentSectionId: (state) => state.currentSectionId,
  nextTitle: (_, getters, rootState) => {
    if (getters.currentIndex === rootState.course.lessonsOrderSum) {
      return null;
    }
    const index = getters.currentIndex + 1;
    return rootState.course.flattenLessonsTitle[index];
  },
  prevTitle: (_, getters, rootState) => {
    if (getters.currentIndex === 0) {
      return null;
    }
    const index = getters.currentIndex - 1;
    return rootState.course.flattenLessonsTitle[index];
  },
  completedLessons: (state) => state.completedLessons,
  completedLessonsProgress: (state, _, rootState) => {
    return parseInt((state.completedLessons.length / rootState.course.lessonsOrderSum) * 100);
  },
  categorizedSections: (state) => state.categorizedSections,
  currentCategorizedLessonLocation: (state) => state.currentCategorizedLessonLocation,
  currentCategory: (state, getters) => {
    const { categoryIndex } = getters.currentCategorizedLessonLocation;
    return state.categorizedSections[categoryIndex];
  },
  currentCategorizedSection: (_, getters) => {
    const currentCategory = getters.currentCategory;
    const { sectionIndex } = getters.currentCategorizedLessonLocation;
    return currentCategory?.sections[sectionIndex];
  },
  currentCategorizedLesson: (_, getters) => {
    try {
      const currentCategorizedSection = getters.currentCategorizedSection;
      const { lessonIndex } = getters.currentCategorizedLessonLocation;
      return currentCategorizedSection.lessons[lessonIndex];
    } catch {}
  },
  nextCategorizedLessonLocation: (_, getters) => {
    const location = { ...getters.currentCategorizedLessonLocation };
    // check next lesson of current section
    const nextLessonIndex = location.lessonIndex + 1;
    const nextLesson = getters.currentCategorizedSection.lessons[nextLessonIndex];
    if (nextLesson) {
      return {
        ...location,
        lessonIndex: nextLessonIndex,
      };
    }
    // check first lesson of next section
    const nextSectionIndex = location.sectionIndex + 1;
    const nextSection = getters.currentCategory.sections[nextSectionIndex];
    if (nextSection) {
      return {
        ...location,
        lessonIndex: 0,
        sectionIndex: nextSectionIndex,
      };
    }
    // check first lesson of first section of next category
    const nextCategoryIndex = location.categoryIndex + 1;
    const nextCategory = getters.categorizedSections[nextCategoryIndex];
    if (nextCategory) {
      return {
        lessonIndex: 0,
        sectionIndex: 0,
        categoryIndex: nextCategoryIndex,
      };
    }
  },
  previousCategorizedLessonLocation: (_, getters) => {
    const location = { ...getters.currentCategorizedLessonLocation };
    // check previous lesson of current section
    const previousLessonIndex = location.lessonIndex - 1;
    const previousLesson = getters.currentCategorizedSection.lessons[previousLessonIndex];
    if (previousLesson) {
      return {
        ...location,
        lessonIndex: previousLessonIndex,
      };
    }
    // check last lesson of previous section
    const previousSectionIndex = location.sectionIndex - 1;
    const previousSection = getters.currentCategory.sections[previousSectionIndex];
    if (previousSection) {
      return {
        ...location,
        lessonIndex: previousSection.lessons.length - 1,
        sectionIndex: previousSectionIndex,
      };
    }
    // check last lesson of last section of previous category
    const previousCategoryIndex = location.categoryIndex - 1;
    const previousCategory = getters.categorizedSections[previousCategoryIndex];
    if (previousCategory) {
      const lastSectionIndex = previousCategory.sections.length - 1;
      const lastLessonIndex = previousCategory.sections[lastSectionIndex].lessons.length - 1;
      return {
        lessonIndex: lastLessonIndex,
        sectionIndex: lastSectionIndex,
        categoryIndex: previousCategoryIndex,
      };
    }
  },
  nextCategorizedLesson: (_, getters) => {
    const nextCategorizedLessonLocation = getters.nextCategorizedLessonLocation;
    if (nextCategorizedLessonLocation) {
      const { lessonIndex, sectionIndex, categoryIndex } = nextCategorizedLessonLocation;
      return getters.categorizedSections[categoryIndex].sections[sectionIndex].lessons[lessonIndex];
    }
  },
  previousCategorizedLesson: (_, getters) => {
    const previousCategorizedLessonLocation = getters.previousCategorizedLessonLocation;
    if (previousCategorizedLessonLocation) {
      const { lessonIndex, sectionIndex, categoryIndex } = previousCategorizedLessonLocation;
      return getters.categorizedSections[categoryIndex].sections[sectionIndex].lessons[lessonIndex];
    }
  },
  currentSubject: (_, getters) => {
    const subjects = getters.categorizedSections;
    return subjects.filter((section) => section.isExpanded)[0];
  },
  currentSection: (_, getters) => {
    const currentSubject = getters.currentSubject;
    const currentSection = currentSubject?.sections.filter((section) => section.isExpanded)[0];
    return currentSection;
  },
};
