import { render, staticRenderFns } from "./navbar-menu.vue?vue&type=template&id=0d275316"
import script from "./navbar-menu.vue?vue&type=script&lang=js"
export * from "./navbar-menu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavbarSidebarToggle: require('/app/components/navbar/navbar-sidebar-toggle.vue').default,NavbarLogo: require('/app/components/navbar/navbar-logo.vue').default,NavbarHubButton: require('/app/components/navbar/navbar-hub-button.vue').default,NavbarTopbar: require('/app/components/navbar/navbar-topbar.vue').default,ProgressCircle: require('/app/components/ui/progress/progress-circle.vue').default,NavbarProfileDropdown: require('/app/components/navbar/navbar-profile-dropdown.vue').default,NavbarHubSidebar: require('/app/components/navbar/navbar-hub-sidebar.vue').default})
