import { MCATInterceptor } from '../interceptors/mcat.interceptor';
import { DATInterceptor } from '../interceptors/dat.interceptor';
import { SATInterceptor } from '../interceptors/sat.interceptor';
import { HighlightService } from './highlight/highlight.service';
import types from '~/store/quiz/types';

export default class QuizService {
  constructor(context) {
    this.context = context;
  }

  /**
   * Get getter value of quiz module
   * @param {String} getterName the name of getter
   * @returns getter value
   * @author HN
   */
  quizGetters(getterName) {
    return this.context.store.getters[`quiz/${getterName}`];
  }

  /**
   * We have sections array in the store. The user wants to start test with specific section id.
   * In some case we know the section id but in other cases we have only the question id that user wants to see.
   * If we know just the question id, we call an API to find it section id and we can modify question index and section index with them.
   * If we know both question id and section id that user want to see, we should modify indexes only.
   * After that we can set the active section index to store.
   * @param {Object} quiz The quiz object that received from the server
   * @param {Number} testId The test id
   * @param {Number} questionId The question id that we want to start test from it
   * @param {Number|undefined} examSectionId The exam section id that we want to start test from it
   * @author HN
   */
  async initialSectionIndexForSpecificQuestion(testId, questionId, examSectionId = null) {
    if (!examSectionId) {
      const { section_id, parent_section_id } = await this.context.store.dispatch('quiz/fetchSectionIdByQuestion', {
        testId,
        questionId,
      });
      const studyableSection = this.findStudyableSectionBetweenTwoSections(parent_section_id, section_id);
      examSectionId = studyableSection?.id;
    }
    const sectionIndex = this.quizGetters('studyableSections').findIndex((section) => section.id === examSectionId);
    if (sectionIndex === -1) {
      throw new Error('Section Not Found.');
    }
    await this.context.store.commit(`quiz/${types.SET_SECTION_INDEX}`, sectionIndex);
  }

  /**
   * We have questions array in the store. The user wants to start test with specific question id.
   * So we should find it index from our array and set it index in the store as the active question index.
   * @param {Object} quiz The quiz object that received from the server
   * @param {Number} questionId The question id that we want to start test from it
   * @author HN
   */
  async initialQuestionIndexForSpecificQuestion(quiz, questionId) {
    const questionIndex = quiz.questions.findIndex((question) => question.id === questionId);
    if (questionId && questionIndex === -1) {
      throw new Error('Question Not Found.');
    }
    await this.context.store.commit(`quiz/${types.SET_QUESTION_INDEX}`, questionIndex);
  }

  /**
   * This method is trn after active section changed
   * We should have an interceptor for each quiz type and apply them based on current quiz type and return modified quiz.
   * Because each quiz type have some logics that another types haven't so we need interceptor for them.
   * Interceptor that is place for changes that have only for specific quiz type
   * @param {Object} quiz The quiz object that received from the server
   * @returns modified quiz object
   * @author HN
   */
  applyInterceptors(quiz) {
    return this.getInterceptor(quiz);
  }

  getInterceptor(quiz) {
    switch (quiz.test.test_type.toLowerCase()) {
      case 'mcat':
        return new MCATInterceptor(quiz, this.context);
      case 'dat':
        return new DATInterceptor(quiz, this.context);
      case 'sat':
        return new SATInterceptor(quiz, this.context);
    }
  }

  /**
   * Call an API every 15 seconds to update test_duration on the server.
   * @param {Number} testId The test id
   * @author HN
   */
  startBackgroundTimer() {
    const test = this.quizGetters('test');
    this.stopBackgroundTimer();
    this.backgroundTimerInterval = setInterval(() => {
      const activeSection = this.quizGetters('activeSection');
      const actualSection = this.quizGetters('actualSection');
      const studyableSection = this.findStudyableSectionBetweenTwoSections(
        actualSection.section_id,
        activeSection.section_id,
      );
      this.context.$api.quiz.syncBackgroundTimer(test.id, studyableSection.section_id);
    }, 15000);
  }

  stopBackgroundTimer() {
    clearInterval(this.backgroundTimerInterval);
    this.backgroundTimerInterval = null;
  }

  isActiveQuestionIntervalRunning() {
    return !!this.activeQuestionInterval;
  }

  /**
   * Start timer on activeQuestion every time it changes.
   * @author HN
   */
  startActiveQuestionTimer() {
    const isTestFinished = this.quizGetters('isTestFinished');
    if (isTestFinished || this.isActiveQuestionIntervalRunning()) {
      return;
    }
    this.activeQuestionInterval = setInterval(() => {
      this.context.store.dispatch('quiz/increaseActiveQuestionTimer');
    }, 1000);
  }

  /**
   * stop the interval of question
   * @author HN
   */
  stopActiveQuestionTimer() {
    clearInterval(this.activeQuestionInterval);
    this.activeQuestionInterval = null;
  }

  /**
   * redirect to exam landing page by accessing the needed slugs from state
   * @author HN
   */
  redirectToExamLandingPage() {
    const quiz = this.quizGetters('quiz');
    const { app_slug: app, daily_slug: daily } = quiz;
    this.context.redirect({ name: 'exams-app-daily', params: { app, daily } });
  }

  /**
   * Finish Current Section
   * In some exams, the parent sections are studyable (DAT, MCAT), so we will finish them after user enters to the last question
   * but we have some exams that their subsections are studyable (SAT), so we will finish all of them and then finish the parent section at the end of last subsection.
   */
  async finishSection() {
    const test = this.quizGetters('test');
    const parentSection = this.quizGetters('activeSection');
    const subSection = this.quizGetters('actualSection');
    const sections = this.quizGetters('sections');

    const finishingSection = subSection.can_study ? subSection : parentSection;

    await this.context.store.dispatch('quiz/finishSection', {
      testId: test.id,
      sectionId: finishingSection.section_id,
      examSectionId: finishingSection.id,
    });

    const isSubsectionStudyable = subSection.can_study;
    const isParentSectionStudyable = parentSection.can_study;
    if (isSubsectionStudyable && !isParentSectionStudyable) {
      const subSections = sections.filter((section) => section.parent_id === subSection.parent_id) ?? [];
      const isLastSubsection = subSections[subSections.length - 1].section_id === subSection.section_id;

      if (isLastSubsection) {
        await this.context.store.dispatch('quiz/finishSection', {
          testId: test.id,
          sectionId: parentSection.section_id,
          examSectionId: parentSection.id,
        });
      }
    }
  }

  /**
   * Finish the current test
   * @author HN
   */
  async finishTest() {
    try {
      const test = this.quizGetters('test');

      this.stopBackgroundTimer();
      this.stopActiveQuestionTimer();

      await this.context.store.dispatch('quiz/finishTest', test.id);
    } catch (err) {
      throw new Error(err);
    }
  }

  /**
   * Initial questions and passages for specific question id or first section
   * If questionId provided, we should find the section of that questionId
   * @param {Null|Number} questionId the question id
   * @author HN
   */
  async initialQuestionsAndPassages(questionId = null, examSectionId = null) {
    const test = this.quizGetters('test');

    const testId = test.id;
    const quiz = { ...this.quizGetters('quiz'), questions: [], passages: [] };

    const isQuizStartedWithSpecificSection = !!examSectionId;
    const isQuizStartedWithSpecificQuestion = !!questionId;

    try {
      if (isQuizStartedWithSpecificSection || isQuizStartedWithSpecificQuestion) {
        await this.initialSectionIndexForSpecificQuestion(testId, questionId, examSectionId);
      }

      if (this.quizGetters('activeSectionType') === 'question') {
        const { data } = await this.context.$api.quiz.fetchPassagesAndQuestions({
          testId,
          sectionId: this.quizGetters('actualSection').section_id,
        });
        quiz.questions = data.questions;
        quiz.passages = data.passages;
      }

      const modifiedQuiz = this.applyInterceptors(quiz).modifyQuestions().modifyPassages().quiz;
      this.context.store.commit(`quiz/${types.SET_QUIZ}`, modifiedQuiz);

      if (isQuizStartedWithSpecificQuestion) {
        await this.initialQuestionIndexForSpecificQuestion(modifiedQuiz, questionId);
      }
    } catch (err) {
      throw new Error(err);
    }
  }

  /**
   *
   * @param {String} key Name of the section property to find with
   * @param {Number} id
   * @returns Section entity or undefined
   */
  findSectionBy(key, id) {
    const sections = this.quizGetters('sections');
    return sections.find((section) => section[key] === id);
  }

  findStudyableSectionBetweenTwoSections(sectionId, subSectionId, key = 'section_id', subKey = 'section_id') {
    const parentSection = this.findSectionBy(key, sectionId);
    const subSection = this.findSectionBy(subKey, subSectionId);
    return [parentSection, subSection].find((section) => section?.can_study);
  }

  get highlight() {
    return new HighlightService(this.context);
  }
}
