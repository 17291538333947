import { render, staticRenderFns } from "./navbar-sidebar-show-more.vue?vue&type=template&id=21a7c5b0"
import script from "./navbar-sidebar-show-more.vue?vue&type=script&lang=js"
export * from "./navbar-sidebar-show-more.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/app/components/ui/icon/icon.vue').default})
