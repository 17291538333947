
import { mapActions } from 'vuex';
import Countdown from '@chenfengyuan/vue-countdown';

export default {
  components: {
    Countdown,
  },

  props: {
    redirect: {
      type: String,
      default: null,
    },

    email: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      two_factor_code: '',
      errors: {
        two_factor_code: '',
        email: '',
      },
      resendEnabled: false,
    };
  },

  computed: {
    resendTimer() {
      return this.$config.resendTimer;
    },
    isInAuthPages() {
      return ['login', 'register'].includes(this.$route.name);
    },
    isPopUp() {
      const refValue = this.$route.query.ref;
      return refValue === 'popup';
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs?.verificationCodeInput?.focus();
    });
  },

  methods: {
    ...mapActions('student', ['updateProfile']),
    ...mapActions('user', ['modifyUserMeta']),

    async login() {
      try {
        this.loading = true;
        const { data, meta } = await this.$auth.loginWith('sanctum', {
          data: {
            email: this.email,
            two_factor_code: this.two_factor_code,
          },
        });
        await this.$auth.setUser({ ...data, ...(meta || {}) });
        this.$store.dispatch('user/setUserMeta', meta || {});
        this.$gtmEvent.dispatch('sign_in', {
          sign_in_method: 'Email',
          successful: true,
        });

        await this.$auth.$storage.setUniversal('loggedIn', true);
        await this.$auth.$storage.setUniversal('_token.sanctum', false);

        // update is_trackable meta the user after login with the saved data in the cookie
        const isTrackable = this.$cookiz.get('is_trackable');
        if (isTrackable !== undefined) {
          await this.$services.user.choiceTrackingCookie(isTrackable);
          this.$cookiz.remove('is_trackable');
        }

        this.$emit('loggedin');
        this.loading = false;
        this.$nuxt.$emit(this.$config.event.logged_in, true);
        this.$nuxt.$emit(this.$config.event.show_login, { value: false });
        this.$hub.fetch();

        if (this.isPopUp) {
          this.$emit('notifyAuthStatus', { success: true });
          return;
        }

        if (this.isInAuthPages) {
          this.$router.push({ name: 'student-verify', query: this.$route.query });
          return;
        }

        if (this.$route.query.redirect) {
          location.replace(this.$route.query.redirect);
          return;
        }

        if (this.redirect) {
          this.$router.push({ path: this.redirect });
        }
      } catch (e) {
        this.loading = false;
        this.errors = this.$getErrors(e) || this.errors;

        if (this.isPopUp) {
          this.$emit('notifyAuthStatus', { success: false });
        }

        this.$gtmEvent.dispatch('sign_in', {
          sign_in_method: 'Email',
          successful: false,
        });
      }
    },

    onCountDownEnded() {
      this.resendEnabled = !this.resendEnabled;
    },

    async resend(event) {
      event.target.focus();
      this.startCountDown();
      this.resendEnabled = false;
      await this.$auth.loginWith('sanctum', {
        data: {
          email: this.email,
        },
      });
    },

    startCountDown() {
      this.$refs.countdown.totalMilliseconds = this.resendTimer;
      this.$refs.countdown.start();
    },
  },
};
